import React from "react"

import Layoutalt from "../components/layoutalt"
import SEO from "../components/seo"
import Partners from "..//components/partners/partners"

const PartenairesPage = () => (
  <Layoutalt>
    <SEO title="Les partenaires du projet TechYourChance" />

    <section className="container small">
      <h1>Les partenaires du projet TYC</h1>
      <p>TechYourChance est un projet qui a vu le jour à Tournai en 2018 via l'impulsion d'entrepreneurs soucieux d'améliorer la maturité numérique de notre région.</p>
    </section>

    <Partners />

    <section className="container small">
      <h2>Remerciements</h2>
      <p>Nous tenons aussi à remercier toutes les personnes qui se sont impliquées dans notre projet depuis le début.</p>
      <p><strong>Merci à</strong> Domitille et Sandy pour l'étincelle; à toute l'équipe de LeCercle ASBL pour avoir porté le projet à ses débuts; Pascal Vermoeren pour sa contribution et son temps passé;
      Domitille, Florette et Luc pour toute l'expérience qu'ils ont pu nous apporter; Dominique Van Horcke et l'ASBL IFI pour leur soutien et leurs conseils avisés; l'Esco, COFFEE et Entreprendre.Wapi pour leur support; Pascal Balancier et Digital Wallonia pour leur accompagnement; le Choq, le Waps'hub et le Fablab de Tournai pour leur aide; ceux que nous avons peut-être oublié tellement ces années ont été intenses.</p>
    </section>
  </Layoutalt>
)

export default PartenairesPage
